.addtoListStyle{
    background-color:#56CFE1;
    position: relative;
    color:white;
    width:15vw;
    height:2.5vw;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    font-family: verdana, serif;
    font-size:1vw;
    margin-left:1.5vw;
    border-radius: 1.5vw;
}
.addtoListStyle:after {
    content: "";
    background: #ffffff;
    display: block;
    position: absolute;
    padding-top: 2.5vw;
    padding-left: 18vw;
    margin-left: 0 !important;
    margin-top: 0;
    opacity: 0;
    transition: all 0.8s
}

.addtoListStyle:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}
