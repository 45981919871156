@font-face {
    font-family: 'Caviar';
    src: local('Caviar'),url('./assets/fonts/caviar_dreams/CaviarDreams.ttf') format('truetype');

}
@font-face {
    font-family: 'CaviarBold';
    src: local('CaviarBold'),url('./assets/fonts/caviar_dreams/CaviarDreams_Bold.ttf') format('truetype');

}
@font-face {
    font-family: 'CaviarBoldItalic';
    src: local('CaviarBoldItalic'),url('./assets/fonts/caviar_dreams/CaviarDreams_BoldItalic.ttf') format('truetype');

}
@font-face {
    font-family: 'CaviarItalic';
    src: local('CaviarItalic'),url('./assets/fonts/caviar_dreams/CaviarDreams_Italic.ttf') format('truetype');

}
@font-face {
    font-family: 'Actor';
    src: local('Actor'),url('./assets/fonts/Actor/Actor-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Adamina';
    src: local('Adamina'),url('./assets/fonts/Adamina/Adamina-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Akronim';
    src: local('Akronim'),url('./assets/fonts/Akronim/Akronim-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Aldrich';
    src: local('Aldrich'),local('Akronim'),url('./assets/fonts/Aldrich/Aldrich-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Allura';
    src: local('Allura'),url('./assets/fonts/Allura/Allura-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Almendra';
    src: local('Almendra'),url('./assets/fonts/Almendra/Almendra-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'AlmendraBold';
    src: local('AlmendraBold'),url('./assets/fonts/Almendra/Almendra-Bold.ttf') format('truetype');

}
@font-face {
    font-family: 'AlmendraItalic';
    src: local('AlmendraItalic'),url('./assets/fonts/Almendra/Almendra-Italic.ttf') format('truetype');

}
@font-face {
    font-family: 'AlmendraBoldItalic';
    src: local('AlmendraBoldItalic'),url('./assets/fonts/Almendra/Almendra-BoldItalic.ttf') format('truetype');

}
@font-face {
    font-family: 'Amiri';
    src: local('Amiri'),url('./assets/fonts/Amiri/Amiri-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'AmiriBold';
    src: local('AmiriBold'),url('./assets/fonts/Amiri/Amiri-Bold.ttf') format('truetype');

}
@font-face {
    font-family: 'AmiriBoldItalic';
    src: local('AmiriBoldItalic'),url('./assets/fonts/Amiri/Amiri-BoldSlanted.ttf') format('truetype');

}
@font-face {
    font-family: 'AmiriItalic';
    src: local('AmiriItalic'),url('./assets/fonts/Amiri/Amiri-Slanted.ttf') format('truetype');

}
@font-face {
    font-family: 'Andika';
    src: local('Andika'),url('./assets/fonts/Andika/Andika-R.ttf') format('truetype');

}
@font-face {
    font-family: 'Aladdin';
    src: local('Aladdin'),url('./assets/fonts/Aladdin/aladdin.ttf') format('truetype');

}
@font-face {
    font-family: 'Arial';
    font-weight: normal;
    src: local('Arial'),url('./assets/fonts/Arial/arial.ttf') format('truetype');

}
@font-face {
    font-family: 'ArialBold';
    font-weight: bold;
    src: local('ArialBold'),url('./assets/fonts/Arial/arialb.ttf') format('truetype');

}
@font-face {
    font-family: 'ArialBoldItalic';
    src: local('ArialBoldItalic'),url('./assets/fonts/Arial/arialbi.ttf') format('truetype');

}
@font-face {
    font-family: 'ArialItalic';
    src: local('ArialItalic'),url('./assets/fonts/Arial/ariali.ttf') format('truetype');

}
@font-face {
    font-family: 'Arimo';
    src: local('Arimo'),url('./assets/fonts/Arimo/Arimo-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'ArimoBold';
    src: local('ArimoBold'),url('./assets/fonts/Arimo/Arimo-Bold.ttf') format('truetype');

}
@font-face {
    font-family: 'ArimoBoldItalic';
    src: local('ArimoBoldItalic'),url('./assets/fonts/Arimo/Arimo-BoldItalic.ttf') format('truetype');

}
@font-face {
    font-family: 'ArimoItalic';
    src: local('ArimoItalic'),url('./assets/fonts/Arimo/Arimo-Italic.ttf') format('truetype');

}
@font-face {
    font-family: 'Arizonia';
    src: local('Arizonia'),url('./assets/fonts/Arizonia/Arizonia-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Baloo';
    src: local('Baloo'),url('./assets/fonts/Baloo/Baloo-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Basic';
    src: local('Basic'),url('./assets/fonts/Basic/Basic-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Baumans';
    src: local('Baumans'),url('./assets/fonts/Baumans/Baumans-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Bayon';
    src: local('Bayon'),url('./assets/fonts/Bayon/Bayon.ttf') format('truetype');

}
@font-face {
    font-family: 'Bmhanna';
    src: local('Bmhanna'),url('./assets/fonts/Bmhanna/BM-HANNA.ttf') format('truetype');

}
@font-face {
    font-family: 'Bodoni';
    src: local('Bodoni'),url('./assets/fonts/bodoni/Bodonitown.ttf') format('truetype');

}
@font-face {
    font-family: 'Bonbon';
    src: local('Bonbon'),url('./assets/fonts/Bonbon/Bonbon-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Cambo';
    src: local('Cambo'),url('./assets/fonts/Cambo/Cambo-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Candal';
    src: local('Candal'),url('./assets/fonts/Candal/Candal.ttf') format('truetype');

}
@font-face {
    font-family: 'Capriola';
    src: local('Capriola'),url('./assets/fonts/Capriola/Capriola-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Caudex';
    src: local('Caudex'),url('./assets/fonts/Caudex/Caudex-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'CaudexBold';
    src: local('CaudexBold'),url('./assets/fonts/Caudex/Caudex-Bold.ttf') format('truetype');

}
@font-face {
    font-family: 'CaudexItalic';
    src: local('CaudexItalic'),url('./assets/fonts/Caudex/Caudex-Italic.ttf') format('truetype');

}
@font-face {
    font-family: 'CaudexBoldItalic';
    src: local('CaudexBoldItalic'),url('./assets/fonts/Caudex/Caudex-BoldItalic.ttf') format('truetype');

}
@font-face {
    font-family: 'Dekko';
    src: local('Dekko'),url('./assets/fonts/Dekko/Dekko-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Delius';
    src: local('Delius'),url('./assets/fonts/Delius/Delius-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Dorsa';
    src: local('Dorsa'),url('./assets/fonts/Dorsa/Dorsa-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Electrolize';
    src: local('Electrolize'),url('./assets/fonts/Electrolize/Electrolize-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Faunaone';
    src: local('Faunaone'),url('./assets/fonts/Faunaone/FaunaOne-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Gabriela';
    src: local('Gabriela'),url('./assets/fonts/Gabriela/Gabriela-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Gafata';
    src: local('Gafata'),url('./assets/fonts/Gafata/Gafata-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Geostar';
    src: local('Geostar'),url('./assets/fonts/Geostar/Geostar-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Habibi';
    src: local('Habibi'),url('./assets/fonts/Habibi/Habibi-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Iceberg';
    src: local('Iceberg'),url('./assets/fonts/Iceberg/Iceberg-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'Lato';
    src: local('Lato'),url('./assets/fonts/lato/Lato-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'LatoBold';
    src: local('LatoBold'),url('./assets/fonts/lato/Lato-Bold.ttf') format('truetype');

}
@font-face {
    font-family: 'LatoItalic';
    src: local('LatoItalic'),url('./assets/fonts/lato/Lato-Italic.ttf') format('truetype');

}
@font-face {
    font-family: 'LatoBoldItalic';
    src: local('LatoBoldItalic'),url('./assets/fonts/lato/Lato-BoldItalic.ttf') format('truetype');

}
@font-face {
    font-family: 'PtSans';
    src: local('PtSans'),url('./assets/fonts/ptsans/PT_Sans-Web-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'PtSansBold';
    src: local('PtSansBold'),url('./assets/fonts/ptsans/PT_Sans-Web-Bold.ttf') format('truetype');

}
@font-face {
    font-family: 'PtSansItalic';
    src: local('PtSansItalic'),url('./assets/fonts/ptsans/PT_Sans-Web-Italic.ttf') format('truetype');

}
@font-face {
    font-family: 'PtSansBoldItalic';
    src: local('PtSansBoldItalic'),url('./assets/fonts/ptsans/PT_Sans-Web-BoldItalic.ttf') format('truetype');

}
@font-face {
    font-family: 'SourceSansPro';
    src: local('SourceSansPro'),url('./assets/fonts/Sourcesanspro/SourceSansPro-Regular.ttf') format('truetype');

}
@font-face {
    font-family: 'SourceSansProBold';
    src: local('SourceSansProBold'),url('./assets/fonts/Sourcesanspro/SourceSansPro-Bold.ttf') format('truetype');

}
@font-face {
    font-family: 'SourceSansProItalic';
    src: local('SourceSansProItalic'),url('./assets/fonts/Sourcesanspro/SourceSansPro-Italic.ttf') format('truetype');

}
@font-face {
    font-family: 'SourceSansProBoldItalic';
    src: local('SourceSansProBoldItalic'),url('./assets/fonts/Sourcesanspro/SourceSansPro-BoldItalic.ttf') format('truetype');

}


