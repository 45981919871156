
.__react_component_tooltip {
    border-radius: 3px;
    display: inline-block;
    font-size: 14px !important;
    /*left: 10%!important;*/

    opacity: 0;
    padding-top:8px;
    position: fixed;
    font-family: Verdana, Arial, san-serif, serif;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
    top: -999em;
    visibility: hidden;
    z-index: 999;
    width: auto;
    background-color: #636A30 !important;
}





.__react_component_tooltip.allow_hover, .__react_component_tooltip.allow_click {
    pointer-events: auto;
}
.__react_component_tooltip:before, .__react_component_tooltip:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
}
.__react_component_tooltip.show {
    opacity: 0.9;
    margin-top: 0px;
    margin-left: 0px;
    visibility: visible;
}
.__react_component_tooltip.type-dark {
    color: #fff;
    background-color: #636A30 !important;
}
.__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #636A30 !important;
    border-top-style: solid;
    border-top-width: 6px;
}
.__react_component_tooltip.type-dark.place-bottom:after {
    border-bottom-color: #636A30 !important;
    border-bottom-style: solid;
    border-bottom-width: 6px;
    left:0!important;
    margin-left: 2px!important;
}
.__react_component_tooltip.type-dark.place-left:after {
    border-left-color: #636A30 !important;
    border-left-style: solid;
    border-left-width: 6px;
}
.__react_component_tooltip.type-dark.place-right:after {
    border-right-color: #636A30 !important;
    border-right-style: solid;
    border-right-width: 0px !important;

}
.__react_component_tooltip.type-dark.border {
    border: 1px solid #fff;
}
.__react_component_tooltip.type-dark.border.place-top:before {
    border-top: 8px solid #fff;
}
.__react_component_tooltip.type-dark.border.place-bottom:before {
    border-bottom: 8px solid #fff;
}
.__react_component_tooltip.type-dark.border.place-left:before {
    border-left: 8px solid #fff;
}
.__react_component_tooltip.type-dark.border.place-right:before {
    border-right: 8px solid #fff;
}
.__react_component_tooltip.type-success {
    color: #fff;
    background-color: #8DC572;
}
.__react_component_tooltip.type-success.place-top:after {
    border-top-color: #8DC572;
    border-top-style: solid;
    border-top-width: 6px;
}
.__react_component_tooltip.type-success.place-bottom:after {
    border-bottom-color: #8DC572;
    border-bottom-style: solid;
    border-bottom-width: 6px;
}
.__react_component_tooltip.type-success.place-left:after {
    border-left-color: #8DC572;
    border-left-style: solid;
    border-left-width: 6px;
}
.__react_component_tooltip.type-success.place-right:after {
    border-right-color: #8DC572;
    border-right-style: solid;
    border-right-width: 6px;
}
.__react_component_tooltip.type-success.border {
    border: 1px solid #fff;
}
.__react_component_tooltip.type-success.border.place-top:before {
    border-top: 8px solid #fff;
}
.__react_component_tooltip.type-success.border.place-bottom:before {
    border-bottom: 8px solid #fff;
}
.__react_component_tooltip.type-success.border.place-left:before {
    border-left: 8px solid #fff;
}
.__react_component_tooltip.type-success.border.place-right:before {
    border-right: 8px solid #fff;
}
.__react_component_tooltip.type-warning {
    color: #fff;
    background-color: #F0AD4E;
}
.__react_component_tooltip.type-warning.place-top:after {
    border-top-color: #F0AD4E;
    border-top-style: solid;
    border-top-width: 6px;
}
.__react_component_tooltip.type-warning.place-bottom:after {
    border-bottom-color: #F0AD4E;
    border-bottom-style: solid;
    border-bottom-width: 6px;
}
.__react_component_tooltip.type-warning.place-left:after {
    border-left-color: #F0AD4E;
    border-left-style: solid;
    border-left-width: 6px;
}
.__react_component_tooltip.type-warning.place-right:after {
    border-right-color: #F0AD4E;
    border-right-style: solid;
    border-right-width: 6px;
}
.__react_component_tooltip.type-warning.border {
    border: 1px solid #fff;
}
.__react_component_tooltip.type-warning.border.place-top:before {
    border-top: 8px solid #fff;
}
.__react_component_tooltip.type-warning.border.place-bottom:before {
    border-bottom: 8px solid #fff;
}
.__react_component_tooltip.type-warning.border.place-left:before {
    border-left: 8px solid #fff;
}
.__react_component_tooltip.type-warning.border.place-right:before {
    border-right: 8px solid #fff;
}
.__react_component_tooltip.type-error {
    color: #fff;
    background-color: #BE6464;
}
.__react_component_tooltip.type-error.place-top:after {
    border-top-color: #BE6464;
    border-top-style: solid;
    border-top-width: 6px;
}
.__react_component_tooltip.type-error.place-bottom:after {
    border-bottom-color: #BE6464;
    border-bottom-style: solid;
    border-bottom-width: 6px;
}
.__react_component_tooltip.type-error.place-left:after {
    border-left-color: #BE6464;
    border-left-style: solid;
    border-left-width: 6px;
}
.__react_component_tooltip.type-error.place-right:after {
    border-right-color: #BE6464;
    border-right-style: solid;
    border-right-width: 6px;
}
.__react_component_tooltip.type-error.border {
    border: 1px solid #fff;
}
.__react_component_tooltip.type-error.border.place-top:before {
    border-top: 8px solid #fff;
}
.__react_component_tooltip.type-error.border.place-bottom:before {
    border-bottom: 8px solid #fff;
}
.__react_component_tooltip.type-error.border.place-left:before {
    border-left: 8px solid #fff;
}
.__react_component_tooltip.type-error.border.place-right:before {
    border-right: 8px solid #fff;
}
.__react_component_tooltip.type-info {
    color: #fff;
    background-color: #337AB7;
}
.__react_component_tooltip.type-info.place-top:after {
    border-top-color: #337AB7;
    border-top-style: solid;
    border-top-width: 6px;
}
.__react_component_tooltip.type-info.place-bottom:after {
    border-bottom-color: #337AB7;
    border-bottom-style: solid;
    border-bottom-width: 6px;
}
.__react_component_tooltip.type-info.place-left:after {
    border-left-color: #337AB7;
    border-left-style: solid;
    border-left-width: 6px;
}
.__react_component_tooltip.type-info.place-right:after {
    border-right-color: #337AB7;
    border-right-style: solid;
    border-right-width: 6px;
}
.__react_component_tooltip.type-info.border {
    border: 1px solid #fff;
}
.__react_component_tooltip.type-info.border.place-top:before {
    border-top: 8px solid #fff;
}
.__react_component_tooltip.type-info.border.place-bottom:before {
    border-bottom: 8px solid #fff;
}
.__react_component_tooltip.type-info.border.place-left:before {
    border-left: 8px solid #fff;
}
.__react_component_tooltip.type-info.border.place-right:before {
    border-right: 8px solid #fff;
}
.__react_component_tooltip.type-light {
    color: #636A30 !important;
    background-color: #fff;
}
.__react_component_tooltip.type-light.place-top:after {
    border-top-color: #fff;
    border-top-style: solid;
    border-top-width: 6px;
}
.__react_component_tooltip.type-light.place-bottom:after {
    border-bottom-color: #fff;
    border-bottom-style: solid;
    border-bottom-width: 6px;
}
.__react_component_tooltip.type-light.place-left:after {
    border-left-color: #fff;
    border-left-style: solid;
    border-left-width: 6px;
}
.__react_component_tooltip.type-light.place-right:after {
    border-right-color: #fff;
    border-right-style: solid;
    border-right-width: 6px;
}
.__react_component_tooltip.type-light.border {
    border: 1px solid #636A30 !important;
}
.__react_component_tooltip.type-light.border.place-top:before {
    border-top: 8px solid #636A30 !important;
}
.__react_component_tooltip.type-light.border.place-bottom:before {
    border-bottom: 8px solid #636A30 !important;
}
.__react_component_tooltip.type-light.border.place-left:before {
    border-left: 8px solid #636A30 !important;
}
.__react_component_tooltip.type-light.border.place-right:before {
    border-right: 8px solid #636A30 !important;
}
.__react_component_tooltip.place-top {
    margin-top: -10px;
}
.__react_component_tooltip.place-top:before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    bottom: -8px;
    left: 50%;
    margin-left: -10px;
}
.__react_component_tooltip.place-top:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
}
.__react_component_tooltip.place-bottom {
    margin-top: 10px;
}
.__react_component_tooltip.place-bottom:before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: -8px;
    left: 50%;
    margin-left: -10px;
}
.__react_component_tooltip.place-bottom:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: -4px;
    left: 0!important;
    margin-left: 0!important;
}
.__react_component_tooltip.place-left {
    margin-left: -10px;
}
.__react_component_tooltip.place-left:before {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    right: -8px;
    top: 50%;
    margin-top: -5px;
}
.__react_component_tooltip.place-left:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    right: -6px;
    top: 50%;
    margin-top: -4px;
}
.__react_component_tooltip.place-right {
    margin-left: 10px;
    margin-top: 40px !important;
}
.__react_component_tooltip.place-right:before {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    left: -8px;
    top: 50%;
    margin-top: -5px;
}
.__react_component_tooltip.place-right:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    left: -6px;
    top: 50%;
    margin-top: -4px;
}
.__react_component_tooltip .multi-line {
    display: block;
    padding: 2px 0px;
    text-align: center;
}
