.image-responsive {
    width:8vw;
    height: 1vw;
    margin-left: 8.5vw;
    position: absolute;

}
.image-responsive-mid {
    width:8vw;
    height: 1vw;
    margin-left: 21vw;
    position: absolute;


}
.image-responsive-end {
    width:8vw;
    height: 1vw;
    margin-left: 8.5vw;
    position: absolute;

}
