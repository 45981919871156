table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ddd;
}

tr {
    text-align: left;
    padding: 16px;
}
